import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'antd';
import { FormItem, Section, Select, Label, Checkbox, BasicModal } from '@luxe/components';
import { UOM, USER_OPTIONS, USER_TYPES } from 'common/Constants';
import theme from 'theme';

const UserSettingsSection = ({ user, form, sso }) => {
  const userType = Form.useWatch('type', form);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (user) {
      let userUnits = UOM.find(uom => uom.value === user.prefs?.units.default);
      let userType = USER_OPTIONS.find(type => type.value === user.type);

      const initialValues = {
        type: userType,
        units: userUnits,
      };

      form.setFieldsValue(initialValues);
    }
  }, [user, form]);

  const rules = {
    userType: [
      {
        required: true,
        message: 'User type is required',
      },
    ],
    units: [
      {
        required: true,
      },
    ],
    email: [
      {
        required: true,
        message: 'The email cannot be blank',
      },
    ],
  };

  return (
    <>
      <Section title={'General'}>
        <Row style={{ width: '100%' }}>
          <Col span={12}>
            <FormItem name="type" label="Type" labelCol={{ span: 24 }} rules={rules.userType}>
              <Select
                options={USER_OPTIONS}
                name="user_types"
                width={'90%'}
                isDisabled={user.type === USER_TYPES.USER}
                styles={{ control: styles => ({ ...styles, pointerEvents: 'auto' }) }}
                onChange={e => {
                  if (e.value === USER_TYPES.USER) {
                    setShowModal(true);
                  }
                }}
              />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem name="units" label="Units" labelCol={{ span: 24 }} rules={rules.units}>
              <Select options={UOM} name="unit_types" width={'90%'} />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem name="phone" label="Phone" labelCol={{ span: 24 }}>
              <Label width={'90%'} />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem name="email" label="Email" labelCol={{ span: 24 }} rules={rules.email}>
              <Label width={'90%'} />
            </FormItem>
          </Col>
        </Row>
        {userType?.value === USER_TYPES.USER && (
          <Row type="flex" style={{ width: '100%', alignItems: 'center' }}>
            <FormItem name="admin" valuePropName="checked" style={{ color: theme.colors.white }}>
              <Checkbox width={'100%'}>Admin</Checkbox>
            </FormItem>
          </Row>
        )}
        {user.type === USER_TYPES.CONTACT && (
          <BasicModal
            title="Confirm"
            visible={showModal}
            onOk={() => {
              setShowModal(false);
            }}
            onCancel={() => {
              form.setFieldsValue({ type: USER_OPTIONS.find(item => item.value === USER_TYPES.CONTACT) });
              setShowModal(false);
            }}
          >
            <p>
              After changing and saving the type to &apos;user&apos; from &apos;contact&apos;, you won&apos;t be able to
              revert it back.
            </p>
          </BasicModal>
        )}
      </Section>
    </>
  );
};

export default UserSettingsSection;
